import './App.css';
import React,{ useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import MainPage from "./pages";
import NotFoundPage from './pages/404';
import Notifications from './pages/Notifications';
import messages from './pages/Messages';
import pdf from './pages/Pdf';
 
import rel from './pages/Rel';
import profile from './pages/Profile';
import TeamPlayer from './modules/teamplayer/TeamPlayer';
import SignUp from './pages/SignUp';
import Logout from './pages/Logout';
import Test from './pages/Test';
import ELibrary from './modules/e-library/ELibrary'
 


function App() {

  const [isAuthenticated,setIsAuthenticated] = useState(false);

  useEffect( () =>{

    if(sessionStorage.getItem('token')){
      setIsAuthenticated(true)
    }

   },[])
   
  return (
     <ThemeProvider theme={theme}>
        <Router>
         
          <div className="App">
          <Switch>
            {/* <Route exact path="/" component={() => <MainPage isAuthenticated={isAuthenticated} />} /> */}
            <Route exact path="/" component={TeamPlayer}/>
            <Route exact path="/signup" component={SignUp}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/Notifications" component={Notifications}/>
            <Route exact path="/Rel" component={rel}/>
            <Route exact path="/Messages" component={messages}/>
            <Route exact path="/Profile" component={profile}/>
            <Route exact path="/team-player/my-tasks" component={() => <TeamPlayer taskType="" />}/>
            <Route exact path="/team-player/sent-tasks" component={() => <TeamPlayer taskType="sent" />}/>
             <Route exact path="/test" component={Test}/>
             <Route exact path="/e-library" component={ELibrary}/>
             <Route exact path="/doc/:id" component={pdf}/>
            
            <Route exact path="/404"/>

            <Redirect to="/404"/>
          </Switch>
          </div>
        </Router>
      </ThemeProvider>
  );
}

export default App;
