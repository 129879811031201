import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core'
import React ,{useState,useEffect} from "react";
import {Controls }from "./controls/Controls";
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';


const useStyles = makeStyles((theme)=>({
    dialog:{
        padding: theme.spacing(2),
        position:'absolute',
        top:theme.spacing(5)
    },
    dialogContent:{
        textAlign:'center'
    },
    dialogAction:{
        justifyContent:'center'
    }

}))
const taskType = [
    {id:'Recieved',title:'Recieved'},
    {id:'Progress',title:'In Progress'},
    {id:'Submitted',title:'Submitted'},
    {id:'Completed',title:'Completed'},
]
export default function StatusUpdate(props) {
    const { confirmDialog,setConfirmDialog,updateTaskStatus}= props;
    const classes = useStyles();
    const [status,setStatus] = useState('');

    const handleInputChange = (e)=>{
        updateTaskStatus(confirmDialog.id,e.target.value)
        setConfirmDialog({...confirmDialog,isOpen:false})
    }

     
    return (
        <Dialog open={confirmDialog.isOpen} classes={{paper: classes.dialog }}>
            <DialogTitle>
                <IconButton disableRipple>
                    <SaveAltRoundedIcon />
                    <Typography variant="h3">
                        {confirmDialog.title}
                    </Typography>
                </IconButton>
                   
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                   
                    <Controls.RadioBox
                        label=""
                        name="type"  
                        items={taskType }
                        onChange={handleInputChange}
                      
                    />
            </DialogContent>
            {<DialogActions className={classes.dialogAction}>
                <Controls.ButtonBox
                onClick={()=> setConfirmDialog({...confirmDialog,isOpen:false})}
                    text="Cancel"
                    color="primary"
                />
                {/* <Controls.ButtonBox
                    text="Update"
                    color="secondary"
                    onClick={confirmDialog.onConfirm}
                     
                /> */}
            </DialogActions> }
        </Dialog>
    )
}
