import React from 'react'
import * as Common from '../Utils/Common'

function NoticeBar() {
    console.log(Common.getUser() )
    return (
        <div className="pf-hd">
            <p> <h3></h3>  <h1>Hi {Common.getUser().id != 0 && Common.getUser().profile.Surname}, Welcome back!</h1></p>
            <i className="la la-ellipsis-v"></i>
         </div>
    )
}

export default NoticeBar;
