import { Dialog, DialogContent, DialogTitle , makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import {Controls }from './controls/Controls'
import CloseIcon from '@material-ui/icons/Close'; 
import { calendarFormat } from 'moment';

const useStyles = makeStyles(theme=>({
    dialogWrapper:{ 
         padding: theme.spacing(2),
         position:'absolute',
         top:theme.spacing(0)

    },
    dialogTitle:{
        paddingRight:'0'
    }
}));

export default function Popup(props) {

    const classes = useStyles(); 

    const  {title, children, openPopup , setOpenPopup} = props; 
    return (
        <Dialog open={openPopup} maxWidth="md" classes={{paper:  classes.dialogWrapper}}>
             <DialogTitle className={classes.dialogTitle}>
                 <div style={{display:'flex'}}>
                 <Typography
                 variant="h3" component="div" style={{flexGrow:1}}
                 >
                     {title }
                 </Typography>
                 <Controls.ActionButton text="x"
                 color="secondary"
                  onClick={()=>{
                      setOpenPopup(false )
                     
                      }}>
                     <CloseIcon />

                 </Controls.ActionButton>

                 </div>
                 
            </DialogTitle>
             <DialogContent dividers>
                {children}
             </DialogContent>

        </Dialog>
    )
}
