import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,Avatar
  } from '@material-ui/core';
  import { Search as SearchIcon } from 'react-feather';
  
  const IhubToolBar= (props) => (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding:'5px;'
        }}
      >
        <Button>
          Import
        </Button>
        <Button sx={{ mx: 1 }}>
          Export
        </Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add New
        </Button>
      </Box>
   </Box>
  );
  
  export default IhubToolBar;
  