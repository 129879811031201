import React from 'react'
import { TextField } from '@material-ui/core';

export default function FileInput(props) {
    const {name,label,value, error=null, onChange,rows,variant='standard', ...other} = props;
    return (
        <TextField
        label={label}
        variant={variant}
        type="file"
        rows={rows}
        name={name}
        value={value}
    
       onChange={onChange} 
       {...other }
       {...(error && {error:true,helperText:error})}/> 
    )
}
