import React from 'react';
import {
    Box,
    Avatar,
    Button,
    Badge,
    Card,
    Container,
    Grid,
    makeStyles,
    Checkbox,
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    InputAdornment,
    Table,
    fabClasses,
    CardContent,
    Typography,
    CardActions,

  } from "@material-ui/core";
import TeamIcon from "@material-ui/icons/SupervisedUserCircle";

export default function Folders(props) {

const {data,loadData,current} = props;

const dataType = ['Departments','Units','DocumentsType']

console.log(data);

    return <Box
        sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            margin: "0 auto",
            border: "1px solid black",
        }}
    >

        {data.map((record)=>{

            return(
                <Card sx={{ width: "190px", height: "190px" }}>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {record.shortName}
                    </Typography>
                    <TeamIcon sx={{ fontSize: 80 }} />
    
                    <Typography variant="body2">
                    {record.name}
                    </Typography>
                </CardContent>
                <CardActions>
                   <Button  sx={{ margin:"0 auto"}} size="small" onClick={()=>{ loadData(current+1)}}>BROWSE</Button> 
                </CardActions>
            </Card>
            );
        })
       }

        
    </Box>;
}
