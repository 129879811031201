import React ,{useState} from 'react'
import { Table , TableHead, Checkbox, TableRow, TablePagination ,TableCell, makeStyles, TableSortLabel, TableContainer} from '@material-ui/core'  




const useStyles = makeStyles(theme =>({
    table: {
         marginTop: theme.spacing(3),
         '& thead th':{
             fontweight:'600',
             color:theme.palette.primary.main,
            // backgroundColor: theme.palette.primary.light,
         },
         '& tbody td':{
             fontweight:'300',
         },
         '& tbody tr:hover':{
             backgroundColor:'#fffbf2',
             cursor:'pointer '
         }

    }
}))


export default function useTable(records,headCells,filterFn) {

    const classes = useStyles();
    
    const pages=[5,10,25,100 ]
    const [page, setPage] = useState(0);
    const [rowsPerPage,setRowsPerPage] = useState(pages[3]);
    const [order,setOrder] = useState(); 
    const [orderBy,setOrderBy] = useState();

    const TableContainerBlock = props =>(
        <TableContainer>
                <Table size="small" className={classes.table }>
                    {props.children}
                </Table>
        </TableContainer>
        

    )
    
    const TableHeader =  props =>{

        const handleSort = (cellId) =>{
                const isAsc = orderBy === cellId && order === "asc"; 
                setOrder(isAsc?'desc':'asc')
                setOrderBy(cellId)
        }
        return (<TableHead>
            <TableRow>
                     <TableCell padding="checkbox">
                        <Checkbox
                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            // inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                {
                    headCells.map(headCell =>(
                        <TableCell key={headCell.id }>
                            <TableSortLabel 
                                active={orderBy === headCell.id? order:'asc'}
                                direction={ orderBy === headCell.id ? order:'asc '}
                                onClick={()=>{handleSort(headCell.id)}}>
                                 {headCell.label}
                            </TableSortLabel>  
                        </TableCell>
                    ))
                }
            </TableRow> 
        </TableHead>)
    }
         
    const handleChangePage =(event, newPage) =>{
         
        setPage(newPage)
         
    }
    const handleChangeRowsPerPage = (event) => {
          
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const TablePaging = () =>(<TablePagination
        component="div"
        page={page}
        rowsPerPageOptions = {pages}
        rowsPerPage={rowsPerPage }
        count={records.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />)
    
    const sortRec = (array,comparator) =>{
        
         const stabilizedThis = array.map((el, index) =>[el,index]);
         stabilizedThis.sort((a,b) =>{
            const _order = comparator(a[0],b[0]);
            if(_order !== 0) return _order;
            return a[1] - b[1];
         });
         return stabilizedThis.map((el)=>el[0])

    }

    const getComparator = (order, orderBy) =>{
        return order === 'desc'
            ? (a,b) => descendingComparator(a,b, orderBy)
            : (a,b) => -descendingComparator(a,b, orderBy);
            
    }

    const descendingComparator = (a,b, orderBy)=>{
        if(b[orderBy] < a[orderBy]){
            return -1;
        }
        if(b[orderBy] > a[orderBy]){
            return 1;
        }
        return 0;
    }

    const pagingAndFilterRecords = () =>{
           
        return  sortRec(filterFn.fn(records) , getComparator(order,orderBy)).slice(page * rowsPerPage, (page+1)* rowsPerPage)
    }
    return {
        TableContainerBlock,
        TableHeader ,
        TablePaging,
        pagingAndFilterRecords
    }

}
