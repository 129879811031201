import React,{ useEffect, useState } from 'react';

import { Link,Redirect } from 'react-router-dom';
import  NavBar  from '../../components/NavBar';
import LeftSide  from '../../components/leftBlock';
import RightSide from '../../components/rightBlock';
import CenterSide from '../../components/centerBlock';
import ChatBoxIcon from '../../components/quickChatBox'
import PostPop from '../../components/SubComponents/makePostPopUp/postPopUp'
import NoticeBar from '../../components/NoticeBar'
import '../teamplayer/TeamPlayer.css'
//import '../../extra-css/dist/css/AdminlteMin.css'


import Tasks from './components/Tasks';
import Header from './components/Header';
 

export default function TeampPlayer(props) {

    const {taskType} = props;
    if(!sessionStorage.getItem('token') ){
        
        return <Redirect to={"/signup"} />;
    }
    return (
        <div className="hub">

        <NavBar/>
        <NoticeBar />
       <main>
           <div className="main-section ">
               <div className="container ">
                   <div className="main-section-data ">
                       <div className="row ">
       
                            <LeftSide />
                             
                            <div className="col-lg-9 col-md-9 no-pd ">


                                 
                                                    <Header />


                                                    <section className="content">
                                               
                                                        <Tasks taskType={taskType}/>
                                               
                                                    </section>
                                        




                            </div>

                        
       
                       </div>
                   </div>
               </div>
           </div>
       </main>
       
       
       {/* <PostPop />
       <ChatBoxIcon />
        */}
       </div>
          
    )
}
