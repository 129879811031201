import * as React from 'react';
import Box from '@material-ui/core/Box';
import {red,green }from '@material-ui/core/colors';


export default function Test() {
  return (
    <Box
      sx={{
        width: 300,
        height: 300,
         backgroundColor: red[600],
        '&:hover': {
          backgroundColor: red[600],
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    />
  );
}