import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react'

export default function SelectBox(props) {

    const {name, label, value, error=null,onChange, options} = props;
     
    return (
        <FormControl  {...(error && {error:true})}>
            <InputLabel>{label}</InputLabel>
            <Select 
                variant="standard" 
                label={label}
                name={name}
                value={value}
                onChange={onChange}>
                <MenuItem value="">None</MenuItem>
                {
                    options.map(
                        item =>(<MenuItem key={item.id } value={item.id}>{item.name}</MenuItem>)
                    )
                }
            </Select>
           { error && <FormHelperText>{error } </FormHelperText>}
        </FormControl>
    )
}
  