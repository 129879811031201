import axios from 'axios';
import { localeData } from 'moment';

const KEYS ={
    tasks:'tasks',
    taskId:'taskId'
}
 
export const addFiles = (data)=>{
    let files= JSON.parse(localStorage.getItem('files'));
    let myArray = [];
    if(files ==null){
        myArray.push(data);
    }else{
        myArray = files;
        myArray.push(data)
    }
   
    //save item in local storage
    localStorage.setItem('files',JSON.stringify(myArray))
    console.log('File saved !')
}

export const addFile = async (data) =>{
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    
   
    let response = await axios.post("file-upload",data,config);
 
    return  response;
   
 }
  

// export const getFiles = (id) => {
//     let files= JSON.parse(localStorage.getItem('files'));
//    console.log("FIlES",files);
//     if(files ==null){
//         return [];
//     }else{
//         return files;
//     }
    
// }

export const getFiles = async (docId) => {

    let response= await axios.get(`files/${docId}`);
    
    return response;
    
 }

export const deleteFiles = (data,selected) => {

    if(selected != null){

        selected.forEach( (file) =>{
            let index = data.findIndex(o => o.file == file);
            if(index != -1){
                data.splice(index,1);

            }
        })

        localStorage.setItem('files',JSON.stringify(data))

    }
}
 
export const getFile = async (docId) => {

    let response= await axios.get(`file/${docId}`);
    
    return response;
    
 }



 
export const getAllTasks = () => {
    let tasks = [];
   
    if(localStorage.getItem(KEYS.tasks) == null)
        localStorage.setItem(KEYS.tasks,JSON.stringify([]))
     tasks = JSON.parse(localStorage.getItem(KEYS.tasks))
    tasks= tasks.reverse()
    //alert(getUser[1].name)
   // let users = getUsersCollections().then(()=>{})
    return tasks.map(x =>({
        ...x,
       //byValue: getUser[x.by].name
        //byValue:''
    }))
}

export const getMyTasks =  async (userId) => {
     
    // axios.get('tasks/'+userId)
    // .then((res)=>{
    //     return res.data
    // }).catch((error)=>{
    //     console.log("oops something went wrong",error.message)
    // })
    console.log("this is User Id",userId)
    const data = await axios.get('tasks/'+userId)
    const tasks = await data.data;
   
    
    return tasks;

    
}

export const getMySentTasks =  async (userId) => {
     
    // axios.get('tasks/'+userId)
    // .then((res)=>{
    //     return res.data
    // }).catch((error)=>{
    //     console.log("oops something went wrong",error.message)
    // })
    const data = await axios.get('tasks/sent-tasks/'+userId)
    const tasks = await data.data;
     
    return tasks;

    
}


export const generateTaskId = () => {
    var id=0;
    alert(localStorage.getItem(KEYS.taskId) )
    if(localStorage.getItem(KEYS.tasksId) == null ){
        localStorage.setItem(KEYS.taskId,id.toString());
    }else{
        alert(id)
         id = parseInt(localStorage.getItem(KEYS.taskId));
    }
        
    localStorage.setItem(KEYS.taskId,(++id).toString());
    return id 


}


export const createTask = (data) => {
    axios.post("tasks",data)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        //console.log(error.message)
    })
}

export const updateTask = (task) =>{
    axios.put("tasks/"+task.id,task)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}

export const updateTaskStatus = (id,status)=>{
    axios.post(`tasks/status/${id}/${status}`)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}


export function deleteTask(id){
    axios.delete("tasks/"+id)
    .then((response)=>{
        console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}


export const getMonthlyTasks = async (month,id) =>{

    const data = await axios.get('tasks/month/'+month+'/user/'+id)
    const tasks = await data.data;
     
    return tasks;
}

export const todaysTasks = async (today) =>{

    const data = await axios.get('tasks/today/' + today)
    const tasks = await data.data;
     
    return tasks;
}

export const completedTasks = async (month) =>{

    const data = await axios.get('tasks/completed/'+month )
    const tasks = await data.data;
     
    return tasks;
}

export const getTaskStats = async (month,id) =>{

    const data = await axios.get('tasks/stat/'+month+'/user/'+id )
    const tasks = await data.data;
     
    return tasks;
}

export const sendNotificaiton = async (id,sender) =>{
    const data = await axios.get(`notification/send/${id}/${sender}`);
    const response = await data.data;

    return response

}