import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react'

export default function RadioBox(props) {
    const {name,label,value,onChange,error=null, items} = props; 
    return (
        <FormControl component="fieldset"  {...(error && {error:true})}>
            <FormLabel>{label}</FormLabel>
                <RadioGroup name={name} value={value} onChange={onChange} row>
                    {
                        items.map(
                            (item,index) =>(
                                <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
                            )
                        )
                    }
                    
                </RadioGroup>
                { error && <FormHelperText>{error } </FormHelperText>}
        </FormControl>
    )
}
