import { Grid, Box, List} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React ,{useState, useEffect}  from 'react'
import ShareIcon from '@material-ui/icons/Share';
import {Controls} from './controls/Controls'
import {useForm , Form }from './useForm';
import * as  TaskService from './../services/TaskService'
import * as Common from '../../../Utils/Common';

const initValues ={
    id:0,
    created_by:Common.getUser().id,
    type:'Task',
    task:'',
    user_id:Common.getUser().id,
   // initDate: new Date(),
    due_date:new Date(),
    priority:'Normal',
    scheduled:new Date(),
    sno:0,
    attachment:'',
    status:'New'

}
const priorities = [
    {id:'Normal', name:'Normal'},
    {id:'Urgent', name:'Urgent'}
]
const taskType = [
    {id:'Request',title:'Request'},
    {id:'Task',title:'Task'},
    {id:'Project',title:'Project'},
]
const statusList = [
    {id:'New',name:'New'},
    {id:'Recieved',name:'Recieved'},
    {id:'Progress',name:'Progress'},
    {id:'Submitted',name:'Submitted'},
    {id:'Completed',name:'Completed'},
]
export default function TaskForm(props) {
    
    const {save, recordsForEdit, popupShareForm} = props;
    
      const validate = (fieldValues =  values ) =>{
          let temp = {...errors}
          if('type' in fieldValues)
              temp.type = fieldValues.type?"":"This field is required.";
          if('task' in fieldValues)
              temp.task = fieldValues.task?"":"This field is required.";
          if('user_id' in fieldValues)
            temp.user_id = fieldValues.user_id.length!=0?"":"This field is required.";
          if('due_date'in fieldValues)
            temp.due_date = fieldValues.due_date?"":"This field is required.";
          setErrors({
              ...temp
          })
        
        if(fieldValues== values)
          return Object.values(temp).every( x => x =="")
      }

      const {values, setValues, errors, setErrors, handleInputChange,resetForm} = useForm(initValues,true,validate );

      const [users, setUsers] = useState([]);
       

      const handleSubmit = (e) =>{
        e.preventDefault()
          if(validate()){ 
            save(values,resetForm);
          }
         
      }

      const share = ()=>{
        popupShareForm(recordsForEdit);
      }
      useEffect(()=>{
        TaskService.getUsersCollections()
        .then(list =>{
            setUsers(list)
        })
          
      },[])

      useEffect(()=>{ 

        if(recordsForEdit != null){
            
            setValues({
                ...recordsForEdit
            })
        }
        
      } ,[recordsForEdit])

      

    return (
         
            <Form onSubmit={handleSubmit}>
                { (values.id !=0 && values.status !="Completed") &&  
                //{ (values.id !=0 && Common.getUser().id == values.created_by && values.status !="Completed")&&    
                <Box sx={{marginLeft:'90%'}}>
                  <a href="#" onClick={share}>Share <ShareIcon> </ShareIcon> 
                  </a>
                </Box>
                }
            <Grid container>
                <Grid item xs={6} sx={{textAlign:"left"}}>
                    <Controls.RadioBox
                        label="Type of Task"
                        name="type" value={values.type} onChange={handleInputChange} 
                        items={taskType }
                        error={errors.type}
                    />
                     
                    {Common.getUser().id == values.created_by && (
                          <Controls.InputBox
                          label="ToDo"
                          rows={3}
                          name="task"
                          value={values.task}
                          onChange={handleInputChange}
                          error={errors.task} />
                    )}
                    {Common.getUser().id != values.created_by && (
                          <Controls.InputBox
                          label="ToDo"
                          rows={3}
                          name="task"
                          value={values.task}
                          disabled
                          onChange={handleInputChange}
                          error={errors.task} />
                    )}

                   
                   {Common.getUser().id == values.created_by && (
                     <div>
                      <Controls.SelectBox
                      label="To"
                      name="user_id"
                      value={values.user_id}
                     onChange={handleInputChange} 
                     options={users}
                     error={errors.user_id}

                     
                    />
                      
                   <Controls.DateBox
                    label="Due Date" 
                    name="due_date"
                    value={values.due_date}
                   onChange={handleInputChange} 
                  /> 
                  </div>
                   )}
                </Grid>
                <Grid item xs={6}>
                {Common.getUser().id == values.created_by && (
                    <Controls.SelectBox
                      label="Priority"
                      name="priority"
                      value={values.priority}
                     onChange={handleInputChange} 
                     options={priorities}
                    />
                )}
                    <Controls.DateBox
                      label="Scheduled Date" 
                      name="scheduled"
                      value={values.scheduled}
                     onChange={handleInputChange} 
                    />
                  <Controls.TextBox
                     label="Order"
                     type="number"
                     name="sno"
                     value={values.sno}
                    onChange={handleInputChange} />
                {Common.getUser().id == values.created_by && (
                    <Controls.SelectBox
                      label="Status"
                      name="status"
                      value={values.status}
                      onChange={handleInputChange} 
                      options={statusList}
                    />
                )}
                  
                  
                       
                           <Box sx={{
                            textAlign: "right"
                        }}>
                        <Controls.ButtonBox 
                            type="submit"
                            text="Save"
                        />
                        {Common.getUser().id == values.created_by && (
                          <Controls.ButtonBox 
                                color="secondary"
                                text="Clear"
                                sx={{margin:"5px"}}
                                onClick={resetForm}
                            />
                          )}
                          </Box>
                        
                  
                </Grid>
            </Grid>
            
        </Form>
    )
}
