import axios from 'axios'


export const createDoc = async (data) =>{
   
   let response = await axios.post("docs",data);

   return  response;
  
}
 
// export const createDoc = (data) =>{
//      console.log('DOC',data)
// }

export const updateDoc = async (doc) =>{

    let response = await axios.put("docs/"+doc.id,doc);

   return response;

}

export const getFiles = async (type,unitId) => {

   let response= await axios.get(`docs/${type}/${unitId}`);
   
   return response;
   
}