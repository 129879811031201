import React,{ useEffect, useState } from 'react';
import axios from 'axios'
import { useHistory , Redirect} from "react-router-dom";
import { setUserSession} from '../Utils/Common';
 

export default function SignUp() {

    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [group, setGroup] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [message, setMessage] = useState('');
    const [error,setError] = useState(null);
    const [email,setEmail] = useState('');
    const history = useHistory();
    const [isAuthenticated,setIsAuthenticated] = useState(false);
    useEffect( () =>{

     const body =   document.getElementsByTagName('body')[0];
     body.classList.add('sign-in');
     console.log('run again');
    },[])

    const  handleFormSubmit = (event) => {
        event.preventDefault();

        
        let data ={
            name : name,
            email: email,
            gender:gender,
            group: group,
            password:password,
            password_confirmation:password_confirmation
        }

        console.log(data);

        axios.post("auth/signup",data)
            .then(
                (res)=>{
                    localStorage.setItem('token','token');
                    console.log('success!!');
                    //show login

                    let tab2 = document.getElementById('tab-2');
                    tab2.classList.remove('current');
                    let tab1 = document.getElementById('tab-1');
                    tab1.classList.add('current');

                    //clear form
                    document.getElementById("register").reset();
                     //clear states
                     setEmail('');
                     setPassword('');
                     setPassword_confirmation('');

                     let msg =(
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    Successful!
                        <span aria-hidden="true">&times;</span>
                       
                    </button>    
                    </div>
                    );
                    setMessage(msg);
             
         
            })
            .catch((err)=>{
                            console.log(err);
            });

                   
        // setMessage(`<div className="alert alert-success alert-dismissible fade show" role="alert">
        // <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        //     <span aria-hidden="true">&times;</span>
        // </button>    
        // </div>`);
        // setAlert('success');

    }

    const  handleLogin = (event) => {
        event.preventDefault();

        
        let data ={
            email: email,
            password:password
        }

        console.log(data);

        axios.post("auth/login",data)
            .then(
                (response)=>{
                    // localStorage.setItem('token',response.data.token);
                    setUserSession(response.data.token,response.data.user)
                    console.log('response >>>',response.data);
                    
                    console.log('success!!');
                    //show login
                    // if(sessionStorage.getItem('token')){
        
                    //     return <Redirect to={"/"} />; 
                    // }

                   window.location="/"


            })
           .catch((err)=>{
               //console.log(err.message)
               if(err.response.status == 401 || err.response.status === 400){
                   let msg= (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                   <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            wrong username or password!
                        
                   
                   </button>    
                   </div>);
                   setError(msg)
               }else{
                   setError('Something Went Wrong ! Please try again later.')
               }
                   console.error('error >>>', err);
            });
        
        // setAlert('success');

    }
    //Redirect to dashboard if loggedIn
    if(sessionStorage.getItem('token')){
        
        return <Redirect to={"/"} />; 
    }
    return (
        <div className="wrapper">


        <div className="sign-in-page">
            <div className="signin-popup">
                <div className="signin-pop">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cmp-info">
                            
                                <h1>IHub Team</h1>
                                <div className="cm-logo">
                                
                                    <p>iHub Team, will help us to work as a team, achieving greater result happily and more effectively through a seemless access and sharing of information from all aspect of the team goals or activities.</p>
                                </div>
                               
                                <img src="images/cm-main-img.png" alt=""/>
                                
                            </div>
                            
                        </div>
                        <div className="col-lg-6">
                            
                            <div className="login-sec">
                                <ul className="sign-control">
                                    <li data-tab="tab-1" className="current"><a href="#" title="">Sign in</a></li>
                                    <li data-tab="tab-2"><a href="#" title="">Sign up</a></li>
                                </ul>
                                
                                <div className="sign_in_sec current" id="tab-1">
                                    
                                    {message}
                                    {error }
                                    
                                    <h3>Sign in</h3>
                                    <form id="login" onSubmit={event => handleLogin(event)}>
                                       
                                        <div className="row">
                                            <div className="col-lg-12 no-pdd">
                                                <div className="sn-field">
                                                    <input type="text" onChange={event => setEmail(event.target.value)} placeholder="Username" />
                                                    <i className="la la-user"></i>
                                                </div>
                                               
                                            </div>
                                            <div className="col-lg-12 no-pdd">
                                                <div className="sn-field">
                                                    <input type="password" onChange={event => setPassword(event.target.value)} placeholder="Password" />
                                                    <i className="la la-lock"></i>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 no-pdd">
                                                <div className="checky-sec">
                                                    <div className="fgt-sec">
                                                        <input type="checkbox" name="cc" id="c1" />
                                                        <label htmlFor="c1">
															<span></span>
														</label>
                                                        <small>Remember me</small>
                                                    </div>
                                                  
                                                    <a href="#" title="">Forgot Password?</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 no-pdd">
                                                <button type="submit" value="submit">Sign in</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="login-resources">
                                          <h4>Login Via Social Account [soon.. ] </h4>
                                        {/* <ul>
                                            <li><a href="#" title="" className="fb"><i className="fa fa-facebook"></i>Login Via Facebook</a></li>
                                            <li><a href="#" title="" className="tw"><i className="fa fa-twitter"></i>Login Via Twitter</a></li>
                                        </ul> */}
                                    </div>
                                  
                                </div>
                               
                                <div className="sign_in_sec" id="tab-2">
                                        <div className="signup-tab">
                                            <i className="fa fa-long-arrow-left"></i>
                                            
                                            <ul>
                                                <li data-tab="tab-3" className="current"><a href="#" title="">Fill in your details</a></li>
                                            
                                            </ul>
                                        </div>
                                   
                                    <div className="dff-tab current" id="tab-3">
                                        <form id="register" onSubmit={event => handleFormSubmit(event)}>
                                            <div className="row">
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <input type="text" 
                                                        onChange={ (e) => setName(e.target.value)}
                                                        placeholder="Full Name"/>
                                                        <i className="la la-user"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <input type="text" 
                                                        onChange={ (e) => setEmail(e.target.value)}
                                                        placeholder="Email..."/>
                                                        <i className="la la-email"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <select onChange={ (e) => setGender(e.target.value)}>
															<option >Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
														</select>
                                                        <i className="la la-dropbox"></i>
                                                        <span><i className="fa fa-ellipsis-h"></i></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <select onChange={ (e) => setGroup(e.target.value)}>
															<option>Group</option>
                                                            <option value="Staff">Staff</option>
                                                            <option value="Client">Client</option>
														</select>
                                                        <i className="la la-dropbox"></i>
                                                        <span><i className="fa fa-ellipsis-h"></i></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <input type="password" onChange={ (e) => setPassword(e.target.value)} placeholder="Password" />
                                                        <i className="la la-lock"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="sn-field">
                                                        <input type="password" onChange={ (e) => setPassword_confirmation(e.target.value)} placeholder="Repeat Password" />  
                                                        <i className="la la-lock"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <div className="checky-sec st2">
                                                        <div className="fgt-sec"> Do you Understand and agree with ihub terms and conditions?
                                                            <input type="checkbox" name="cc" id="c2" />
                                                            <label htmlFor="c2">
																<span></span>
															</label>
                                                            <small>Yes, I understand and agree to the workwise Terms & Conditions.</small>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 no-pdd">
                                                    <button type="submit" value="submit">Get Started</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                  
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
                
            </div>
             
             <div className="footy-sec">
                <div className="container">
                    <ul>
                        <li><a href="#" title="">Help Center</a></li>
                        <li><a href="#" title="">Privacy Policy</a></li>
                        <li><a href="#" title="">Community Guidelines</a></li>
                        <li><a href="#" title="">Cookies Policy</a></li>
                        <li><a href="#" title="">Career</a></li>
                        <li><a href="#" title="">Forum</a></li>
                        <li><a href="#" title="">Language</a></li>
                        <li><a href="#" title="">Copyright Policy</a></li>
                    </ul>
                    <p><img src="images/copy-icon.png" alt=""/>Copyright 2018</p>
                </div>
            </div>
             
        </div>
 
         


    </div>
    )
}
