import { Grid, Box, List} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React ,{useState, useEffect}  from 'react'
import ShareIcon from '@material-ui/icons/Share';
import {Controls} from './controls/Controls'
import {useForm , Form }from './useForm';
import * as  TaskService from './../services/TaskService'
import * as Common from '../../../Utils/Common';
import Details from './DocFormDetails'

const initValues ={
    id:0,
    CreatedBy:Common.getUser().id,
    Type:'',
    DocNo:'',
    Name:'',
    category:'',
    Units:'1',
    Unit:'1',
    // departments:'',
    Description:'',
    DateCreated:null,
    ISO:'',
    DateRevised:null,
    Status:'Published'

}
const category = [
    
]
const departments = [
    
]
const units = [
  {id:'1',name:'ICT'},
    
]
const docType = [
  {id:'Procedure',name:'Procedure'},
  {id:'Form',name:'Form'},
  {id:'Standard',name:'Standard'},
  {id:'Work Instructions',name:'Work Instructions'},
  {id:'Job Description',name:'Job Description'},
  {id:'List',name:'List'},
  {id:'Log Book',name:'Log Book'},
  
]
const statusList = [
    {id:'Draft',name:'Draft'},
    {id:'Published',name:'Published'},
]
export default function DocForm(props) {
    
    const {save, recordsForEdit, popupShareForm,clear} = props;
    
      const validate = (fieldValues =  values ) =>{
          //   let temp = {...errors}
          //   if('type' in fieldValues)
          //       temp.type = fieldValues.type?"":"This field is required.";
          //   if('task' in fieldValues)
          //       temp.task = fieldValues.task?"":"This field is required.";
          //   if('user_id' in fieldValues)
          //     temp.user_id = fieldValues.user_id.length!=0?"":"This field is required.";
          //   if('due_date'in fieldValues)
          //     temp.due_date = fieldValues.due_date?"":"This field is required.";
          //   setErrors({
          //       ...temp
          //   })
          
          // if(fieldValues== values)
          //   return Object.values(temp).every( x => x =="")
      }

      const {values, setValues, errors, setErrors, handleInputChange,resetForm} = useForm(initValues,true,validate );

      const [users, setUsers] = useState([]);
       

      const handleSubmit = (e) =>{
        e.preventDefault()
          // if(validate()){ 
            // console.log("DOCform.js-81",values)
            save(values,resetForm);
          // }
         
      }
 

      const share = ()=>{
        popupShareForm(recordsForEdit);
      }
      useEffect(()=>{
        // console.log("DOCform.js-91",values)
        resetForm()
        TaskService.getUsersCollections()
        .then(list =>{
            setUsers(list)
        })
          
      },[])

      useEffect(()=>{ 

        

        if(recordsForEdit != null){
            
            setValues({
                ...recordsForEdit
            })
        }
        
      } ,[recordsForEdit])

      

    return (
         <>
            <Form onSubmit={handleSubmit}>
              
            <Grid container>
               
                <Grid item xs={12}>
                <Box sx={{
                            textAlign: "right"
                        }}>
                        <Controls.ButtonBox 
                            type="submit"
                            text="Save"
                        />
                       
                          <Controls.ButtonBox 
                                color="secondary"
                                text="Clear"
                                sx={{margin:"5px"}}
                                onClick={resetForm}
                            />
                       
                          </Box>
                </Grid>
                <Grid item xs={6} sx={{textAlign:"left"}}>
                  
                    <Controls.SelectBox
                      label="Type"
                      name="Type"
                      value={values.Type}
                     onChange={handleInputChange} 
                     options={docType}
                     error={errors.Type}
                    />
                    <Controls.TextBox 
                       label="Doc No"
                       name="DocNo"
                       value={values.DocNo}
                       onChange={handleInputChange}
                       error={errors.DocNo} 
                    />

                    <Controls.TextBox 
                       label="Name"
                       name="Name"
                       value={values.Name}
                       onChange={handleInputChange}
                       error={errors.Name} 
                    />
                    
                    <Controls.DateBox
                    label="Created" 
                    name="DateCreated"
                    value={values.DateCreated}
                   onChange={handleInputChange} 
                  /> 
                   <Controls.DateBox
                    label="Last Revised" 
                    name="DateRevised"
                    value={values.DateRevised}
                   onChange={handleInputChange} 
                  /> 
                  <Controls.TextBox 
                       label="Description"
                       name="Description"
                       value={values.Description}
                       onChange={handleInputChange}
                       error={errors.name} 
                    />
                </Grid>
                <Grid item xs={6}>
                <Controls.SelectBox
                      label="Status"
                      name="Status"
                      value={values.Status}
                      onChange={handleInputChange} 
                      options={statusList}
                    />
                    <Controls.SelectBox
                      label="Category"
                      name="category"
                      value={values.category}
                     onChange={handleInputChange} 
                     options={category}
                    />

                    <Controls.TextBox 
                       label="Iso Ref"
                       name="ISO"
                       value={values.isoRef}
                       onChange={handleInputChange}
                       error={errors.isoRef} 
                    />
                    <Controls.SelectBox
                      label="Unit"
                      name="Unit"
                      value={values.Unit}
                     onChange={handleInputChange} 
                     options={units}
                    />
                    <br /><br />
                    <Controls.SelectBox
                      label="Units"
                      name="units"
                      value={values.units}
                     onChange={handleInputChange} 
                     options={units}
                    />
                    <div>
                     Units
                    </div>
                  
                  
               
                  
                  
                       
                         
                        
                  
                </Grid>
            </Grid>
            </Form>
            {values.id !== 0 &&
            <Grid container>
               File List
                         <Details 
                         doc={values}
                         
                         /> 
            </Grid>
            }
       </>
    )
}
