import React from 'react'
import { removeUserSession } from '../Utils/Common';

export default function Logout() {
     
    removeUserSession();
    window.location="/signup"
    return (
        <div>
            
        </div>
    )
}
