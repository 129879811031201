import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import {red,green }from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));
 


export default function DashboardCard(props) {
    const classes = useStyles();
    const {today,total, completed, performance,month} ={...props };

  return( <Card sx={{ height: '85%'}}  {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
        <Grid item sx={{marginBottom:'15px'}}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h8"
            sx={{ marginBottom:'30px' }}
          >
            {month}
            {/* <Grid item>
              <Avatar
              
                sx={{
                  backgroundColor:red[600],
                  height: 36,
                  width: 36,
                  textAlign:'center'
                }}
              >
                <MoneyIcon />
                
              </Avatar>
        </Grid>
       */}
          </Typography>
        </Grid>
        <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              textAlign:'left'
            }}
          >
            Total Tasks: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;{total} 
            
          </Typography>
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              textAlign:'left'
            }}
          >
            Completed : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;{completed} 
            
          </Typography>
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              textAlign:'left'
            }}
          >
            Pending :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;{total-completed} 
            
          </Typography>
         
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              textAlign:'left'
            }}
          >
           
          For Today : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{today}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{
              textAlign:'left'
            }}
          >
            Effectiveness :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Math.round((completed/total)*100)}%
            
          </Typography>
         <Box
        sx={{
     
          display: 'flex',
          alignItems: 'center',
          paddingBottom:'5px'
        }}
      >
        <Typography
          color="textSecondary"
          variant="caption"
          sx={{  color: red[900] }}
        >
          Performance:
        </Typography>
        <Typography
          sx={{
            color: red[900],
            mr: 1
          }}
          variant="body2"
        >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{performance}%
        </Typography>
        <ArrowDownwardIcon sx={{ color: red[900] }} />
      
      </Box>
       
        </Grid>
      </Grid>
      
    </CardContent>
  </Card>)
};

 
