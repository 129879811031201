 
import {Controls} from './controls/Controls'
import {useForm , Form }from './useForm';
import { Grid, Box, List} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React ,{useState, useEffect}  from 'react'
import * as FileService from '../services/FileService'
import { add } from 'date-fns';
import * as Global from '../../../Utils/Common'





import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';




export default function DocFormDetails(props) {

    const initValues ={};

    const {doc} = props;
    const validate = (fieldValues =  values ) =>{
        let temp = {...errors}
        if('type' in fieldValues)
            temp.type = fieldValues.type?"":"This field is required.";
        if('task' in fieldValues)
            temp.task = fieldValues.task?"":"This field is required.";
        if('user_id' in fieldValues)
          temp.user_id = fieldValues.user_id.length!=0?"":"This field is required.";
        if('due_date'in fieldValues)
          temp.due_date = fieldValues.due_date?"":"This field is required.";
        setErrors({
            ...temp
        })
      
      if(fieldValues== values)
        return Object.values(temp).every( x => x =="")
    }

    const {values, setValues, errors, setErrors, handleInputChange,resetForm} = useForm(initValues,true,validate ); 

    const status = [
        {id:'draft',name:'Draft'},
        {id:'published',name:'Published'},
    ]


    const addFile = (data) =>{

      
       
      let _data = new FormData() 
      let _file = file.file

     _data.append('file', _file)
     _data.append('docNo', doc.id)
     _data.append('revision',data.revision)
     _data.append('remark',data.remark)
     _data.append('status',data.status)
     _data.append('by',Global.getUser().id)
   
   
     console.log('THE FILE POST',_data.entries());

     for (var pair of _data.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
     }  
      FileService.addFile(_data)
      .then((response)=>{

          console.log(response.data.message)
          FileService.sendNotification(1,27,doc.id)
          FileService.sendNotification(1,1,doc.id)
          loadRows();
      });
      
    }

    useEffect(()=>{
      // alert("docId"+docId);
      FileService.getFiles(doc.id)
      .then((response)=>{
        setRows(response.data.docs);

        // console.log("DocsTable-97",response.data.docs)
      })
    },[])



    /******************************************************** */
    /************* TABLE *************************************/
    /*********************************************************/

    function createData(name, calories, fat, carbs, protein) {
      return { name, calories, fat, carbs, protein };
    }
    
 
    
    const [rows,setRows] = useState([]);


    const loadRows = () =>{
      

      FileService.getFiles(doc.id)
      .then((response)=>{
        setRows(response.data.docs);

        // console.log("DocsTable-97",response.data.docs)
      })

      

    }

    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }
    
    const headCells = [
      { id: 'name', numeric: false, disablePadding: true, label: 'File Name' },
      { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
      { id: 'revision', numeric: true, disablePadding: false, label: 'Revision' },
      { id: 'remark', numeric: true, disablePadding: false, label: 'Remark' },
      { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    ];
    
  
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };

      function EnhancedTableHead(props) {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
      
        return (
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }
      
      EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
      };
      
      const useToolbarStyles = makeStyles((theme) => ({
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
        },
        highlight:
          theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
              },
        title: {
          flex: '1 1 100%',
        },
      }));
      
      const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;
      
        return (
          <Toolbar
            className={clsx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            {numSelected > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Nutrition
              </Typography>
            )}
      
            {numSelected > 0 ? (
              <Tooltip title="Delete">
                <IconButton aria-label="delete" onClick={()=>{
                  alert('deleting...')

                  FileService.deleteFiles(rows,selected);
                 loadRows();
                }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton aria-label="filter list">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        );
      };
      
      EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
      };
      
      const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
        },
        paper: {
          width: '100%',
          marginBottom: theme.spacing(2),
        },
        table: {
          minWidth: 750,
        },
        visuallyHidden: {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 1,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          top: 20,
          width: 1,
        },
      }));
      



  const classes2 = useStyles();
  const [order2, setOrder2] = React.useState('asc');
  const [orderBy2, setOrderBy2] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [file,setFile] = React.useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy2 === property && order2 === 'asc';
    setOrder2(isAsc ? 'desc' : 'asc');
    setOrderBy2(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleInputFileChange = (e) =>{

    setFile({file:e.target.files[0]});

    // console.log('Here is the file',e.target.files[0])

  }
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);




      
  return (
    <>
    <Grid container>  
    {/* <form onSubmit={addFile(values) } encType="multipart/form-data"> */}

        <Grid item xs={4}>
                    <Controls.FileInput
                       label="File"
                       name="file"
                       value={values.file}
                       onChange={handleInputFileChange}
                       error={errors.file} 
                    />
        </Grid>
        <Grid item xs={1}>
        <Controls.TextBox 
                       label="Rev"
                       name="revision"
                       value={values.revision}
                       onChange={handleInputChange}
                       error={errors.isoRef} 
                    />
        </Grid>
        <Grid item xs={3}>
        <Controls.TextBox 
                       label="Remark"
                       name="remark"
                       value={values.remark}
                       onChange={handleInputChange}
                       error={errors.remark} 
                    />
        </Grid>
        <Grid item xs={2}>
        <Controls.SelectBox
                      label="status"
                      name="status"
                      value={values.status}
                     onChange={handleInputChange} 
                     options={status}
                    />
        </Grid>
        <Grid item xs={1}  sx={{paddingTop:'23px' }} >
        <Controls.ButtonBox 
                       type="submit"
                       text="Add"
                       onClick={()=>{ addFile(values) }}
                      
                    />
        </Grid>
        
    {/* </form> */}
    </Grid>
   

    



    <div className={classes2.root}>
      <Paper className={classes2.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes2.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes2}
              numSelected={selected.length}
              order={order2}
              orderBy={orderBy2}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order2, orderBy2))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.file);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.file)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                      <a href={"/doc/"+row.id} target="_blank">{row.Path}</a>  
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">{row.Revision}</TableCell>
                      <TableCell align="right">{row.Comments}</TableCell>
                      <TableCell align="right">{row.Status}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>













    
    
    </>
  )
}
