import { Button } from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import './leftBlock.css';
import AccountReview from './SubComponents/AccountReviewBlock'


const leftBlock = () => {
    return (

        <div className="col-lg-3 col-md-4 pd-left-none no-pd">
                        <div className="main-left-sidebar no-margin">




                        <AccountReview />


                            <div className="suggestions full-width">
                                <div className="sd-title">
                                    <h3> Top Team Player</h3>
                                    <i className="la la-ellipsis-v"></i>
                                </div>
                                <div className="suggestions-list">
                                    
                                    {/* <div className="suggestion-usd">
                                        <img src="http://via.placeholder.com/35x35" alt=""/>
                                        <div className="sgt-text">
                                            <h4>John Doe</h4>
                                            <span>PHP Developer</span>
                                        </div>
                                        <span><i className="la la-plus"></i></span>
                                    </div>
                                     */}
                                    <div className="view-more">
                                        
                                        <Button disabled>View More</Button>
                                    </div>
                                </div>
                            </div>










                            <div className="tags-sec full-width">
                                <h2>Useful links</h2>
                                <ul>
                                    
                                    <li><Link to="#" title="">Web Mail</Link></li>
                                    {/* <li><Link to="#" title="">About</Link></li>
                                    <li><Link to="#" title="">Privacy Policy</Link></li>
                                    <li><Link to="#" title="">Community Guidelines</Link></li>
                                    <li><Link to="#" title="">Cookies Policy</Link></li>
                                    <li><Link to="#" title="">Career</Link></li>
                                    <li><Link to="#" title="">Language</Link></li>
                                    <li><Link to="#" title="">Copyright Policy</Link></li> */}
                                </ul>
                                <div className="cp-sec">
                                    <img src="ihub.png" height="30" alt=""/>
                                    <p><img src="images/cp.png" alt=""/>Copyright {new Date().getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
    )
}

export default leftBlock;
