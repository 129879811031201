import axios from 'axios';

const KEYS ={
    tasks:'tasks',
    taskId:'taskId'
}
 
 
 
export const getUsersCollections = async () => {
    const data = await axios.get("auth/users")
   const users = await data.data;
   console.log(users)
   
   return users;

}
export const getUser =  (id) => {

     axios.get("auth/users/"+id).then((res)=>{
        return res.data
     })
   
 
  

}


 
export const getAllTasks = () => {
    let tasks = [];
   
    if(localStorage.getItem(KEYS.tasks) == null)
        localStorage.setItem(KEYS.tasks,JSON.stringify([]))
     tasks = JSON.parse(localStorage.getItem(KEYS.tasks))
    tasks= tasks.reverse()
    //alert(getUser[1].name)
   // let users = getUsersCollections().then(()=>{})
    return tasks.map(x =>({
        ...x,
       //byValue: getUser[x.by].name
        //byValue:''
    }))
}

export const getMyTasks =  async (userId) => {
     
    // axios.get('tasks/'+userId)
    // .then((res)=>{
    //     return res.data
    // }).catch((error)=>{
    //     console.log("oops something went wrong",error.message)
    // })
    console.log("this is User Id",userId)
    const data = await axios.get('tasks/'+userId)
    const tasks = await data.data;
   
    
    return tasks;

    
}

export const getMySentTasks =  async (userId) => {
     
    // axios.get('tasks/'+userId)
    // .then((res)=>{
    //     return res.data
    // }).catch((error)=>{
    //     console.log("oops something went wrong",error.message)
    // })
    const data = await axios.get('tasks/sent-tasks/'+userId)
    const tasks = await data.data;
     
    return tasks;

    
}


export const generateTaskId = () => {
    var id=0;
    alert(localStorage.getItem(KEYS.taskId) )
    if(localStorage.getItem(KEYS.tasksId) == null ){
        localStorage.setItem(KEYS.taskId,id.toString());
    }else{
        alert(id)
         id = parseInt(localStorage.getItem(KEYS.taskId));
    }
        
    localStorage.setItem(KEYS.taskId,(++id).toString());
    return id 


}


export const createTask = (data) => {
    axios.post("tasks",data)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        //console.log(error.message)
    })
}

export const updateTask = (task) =>{
    axios.put("tasks/"+task.id,task)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}

export const updateTaskStatus = (id,status)=>{
    axios.post(`tasks/status/${id}/${status}`)
    .then((response)=>{
       // console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}


export function deleteTask(id){
    axios.delete("tasks/"+id)
    .then((response)=>{
        console.log(response.data)
    })
    .catch((error)=>{
        console.log(error.message)
    })

}


export const getMonthlyTasks = async (month,id) =>{

    const data = await axios.get('tasks/month/'+month+'/user/'+id)
    const tasks = await data.data;
     
    return tasks;
}

export const todaysTasks = async (today) =>{

    const data = await axios.get('tasks/today/' + today)
    const tasks = await data.data;
     
    return tasks;
}

export const completedTasks = async (month) =>{

    const data = await axios.get('tasks/completed/'+month )
    const tasks = await data.data;
     
    return tasks;
}

export const getTaskStats = async (month,id) =>{

    const data = await axios.get('tasks/stat/'+month+'/user/'+id )
    const tasks = await data.data;
     
    return tasks;
}

export const sendNotificaiton = async (id,sender) =>{
    const data = await axios.get(`notification/send/${id}/${sender}`);
    const response = await data.data;

    return response

}