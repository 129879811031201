import React from 'react'

function Notifications() {
    return (
        <div className>
            <small>notifications</small>
        </div>
    );
}

export default Notifications;
