import React from "react";
import DashboardCard from "./DashboardCard";
import { Box, Container, Grid } from "@material-ui/core";

export default function MiniDashBoard(props) {

  const {taskUpdates} = props;
  return (
    <>
      {/* <Grid item lg={3} sm={6} xl={3} xs={12} square>
        <DashboardCard today="..."total="..."
        completed="..." performance="..."
        month="Overall Task"/>
      </Grid> */}
      <Grid item lg={3} sm={6} xl={3} xs={12} square>
        <DashboardCard today={taskUpdates.today} total={taskUpdates.total}
        completed={taskUpdates.completed} performance={taskUpdates.performance} 
        month={taskUpdates.month}/>
      </Grid>
      
      {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
        <DashboardCard />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <DashboardCard />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <DashboardCard />
      </Grid> */}
    </>
  );
}
