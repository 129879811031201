import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import {Controls }from "./controls/Controls";
import NotListedLocationIcon  from '@material-ui/icons/NotListedLocation'

const useStyles = makeStyles((theme)=>({
    dialog:{
        padding: theme.spacing(2),
        position:'absolute',
        top:theme.spacing(5)
    },
    dialogContent:{
        textAlign:'center'
    },
    dialogAction:{
        justifyContent:'center'
    }

}))

export default function ConfirmDialog(props) {
    const { confirmDialog,setConfirmDialog}= props;
    const classes = useStyles();
    return (
        <Dialog open={confirmDialog.isOpen} classes={{paper: classes.dialog }}>
            <DialogTitle>
                <IconButton disableRipple>
                    <NotListedLocationIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                    <Typography variant="h6">
                        {confirmDialog.title}
                    </Typography>
                    <Typography variant="subTitle2">
                        {confirmDialog.subTitle}
                    </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Controls.ButtonBox
                onClick={()=> setConfirmDialog({...confirmDialog,isOpen:false})}
                    text="No"
                    color="primary"
                />
                <Controls.ButtonBox
                    text="Yes"
                    color="secondary"
                    onClick={confirmDialog.onConfirm}
                     
                />
            </DialogActions>
        </Dialog>
    )
}
