import React, { useEffect } from 'react'
import { removeUserSession } from '../Utils/Common';
import { Link, useHistory , Redirect} from "react-router-dom";
import * as Common from '../Utils/Common'
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ReportIcon from '@material-ui/icons/Assessment';
import TaskIcon from '@material-ui/icons/Assignment';
import LibraryIcon from '@material-ui/icons/LibraryBooks';
import ChatIcon from '@material-ui/icons/Forum';
import './NavBar.css';
import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';



const StyledBadge = withStyles((theme) => ({
    txtColor:{
        color:'#fff',
        fontSize:'14px'
    },
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);
  
  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);
  
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  



const NavBar = () => {

    const classes = useStyles();

    useEffect(() => {


    }, [])
    const Signout = () => {
        console.log('log out')
        removeUserSession();
        window.location = "/signup"

    }
    return (
        <header>
                <div className= "wrapper">
                <div className="container">
            <div className="header-data">
                <div className="logo">
                    {/* <Link to="index.html" title=""><img src="ihub.png" height="42" alt=""/></Link> */}
                </div>
                <div className="search-bar">
                    <form>
                        <input type="text" name="search" placeholder="Search for colleague, procedure or memo..."/>
                        <button type="submit"><i className="la la-search"></i></button>
                    </form>
                </div>
                <nav>
                    <ul>
                        <li>
                            {/* <Link to="/" title=""> */}
                                {/* <span><img src="images/icon1.png" alt=""/></span> */}
                                <span>
                                    <HomeIcon sx={{marginBottom:'5px'}} color="disabled" />
                                </span>
                               <p>iDesk</p> 
                            {/* </Link> */}
                        </li>
                        <li>
                            <Link to="/team-player/my-tasks" title="">
                              
                                <span>
                                            <TaskIcon sx={{marginBottom:'15px'}}  />
                                        </span>
                                        <p><a className={classes.txtColor}>Task</a> </p>
                                </Link>
                                
                            <ul>
                                <li><Link to="/team-player/my-tasks" title=""> My Tasks </Link></li>
                                        {/* <li><Link to="" title=""> Objectives </Link></li> */}
                                        <li><Link to="/team-player/sent-tasks" title=""> Tasks assigned </Link></li>
                            </ul>
                                </li>
                                <li>
                                    {/* <Link to="projects.html" title=""> */}
                                  
                                        <span>
                                            <PeopleIcon sx={{marginBottom:'5px'}} color="disabled" />
                                        </span>
                                        <p>Team Work</p> 
                                   {/* </Link> */}
                                    {/* <ul>
                                        <li><Link to="" title=""> Finance </Link></li>
                                        <li><Link to="" title=""> Management System </Link></li>
                                    </ul> */}
                                </li>
                                <li>
                            
                                {/* <Link to="profiles.html" title=""> */}
                                         <span>
                                            <ReportIcon sx={{marginBottom:'5px'}} color="disabled" />
                                        </span>
                                        <p>Report</p>
                                {/* </Link> */}
                        </li>
                            <li>
                            <Link to="/e-library/" title="" id="setting">
                                        <span>
                                            <LibraryIcon sx={{marginBottom:'5px'}} />
                                        </span>
                                        <p><a className={classes.txtColor}>E-Library</a> </p>
                                
                                    
                            </Link>
                            </li>
                            <li>
                                 <Link to="profiles.html" title="" id="setting">
                                 <span>
                                            <ChatIcon sx={{marginBottom:'5px'}} color="disabled" />
                                        </span>
                                        <p>Messages</p>
                                
                                    
                            </Link>
                        
                                <div className="notification-box msg">
                                    <div className="nt-title">
                                        <h4>Setting</h4>
                                    <Link to="#" title="">Clear all</Link>
                                </div>
                                <div className="nott-list">
                                    <div className="notfication-details">
                                        <div className="noty-user-img">
                                            <img src="images/resources/ny-img1.png" alt="" />
                                        </div>
                                        <div className="notification-info">
                                            <h3><Link to="/Messages" title="">Colburn QHSE Coordinator </Link> </h3>
                                            <p>Just uploaded a new Standard kindly check the E-libary</p>
                                            <span>2 min ago</span>
                                        </div>
                                    </div>
                                 
                                    <div className="view-all-nots">
                                        <Link to="/Messages" title="">View All Messsages</Link>
                                    </div>
                                </div>
                            </div>
                         
                        </li>
                        <li>
                            <Link to="#" title="" className="not-box-open">
                                      <span>
                                            <TaskIcon sx={{marginBottom:'5px'}}  />
                                        </span>
                                        <p><a className={classes.txtColor}>Notification</a> </p>
                                
                            </Link>
                            <div className="notification-box">
                                <div className="nt-title">
                                    <h4>Setting</h4>
                                    <Link to="#" title="">Clear all</Link>
                                </div>
                                <div className="nott-list">
                                    <div className="notfication-details">
                                        <div className="noty-user-img">
                                            <img src="images/resources/ny-img1.png" alt=""/>
                                        </div>
                                        <div className="notification-info">
                                            <h3><Link to="#" title="">Colburn O </Link> Uploaded a new standard, check E-Library</h3>
                                            <br/><br />
                                            <span>2 min ago</span>
                                        </div>
                                    </div>
                                 
                                    <div className="view-all-nots">
                                        <Link to="#" title="">View All Notification</Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>

                    <div className="menu-btn">
                    <Link to="#" title=""><i className="fa fa-bars"></i></Link>
                </div>

                <div className="user-account">
                    <div className="user-info">
                         <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            <Avatar alt="Remy Sharp"
                             sx={{ height: '35px', width: '40px' }}
                             src={Common.getUser() != undefined && `${Common.baseURL()}/storage/${Common.getUser().photo}`} />
                        </StyledBadge>
                     
                    <i className="la la-sort-down"></i>
                </div>

                <div className="user-account-settingss">
                    <h3>{Common.getUser().name}</h3>
                    <ul className="on-off-status">
                        <li>
                            <div className="fgt-sec">
                                <input type="radio" name="cc" id="c5" />
                                <label htmlFor="c5">
                                    <span></span>
                                </label>
                                <small>Online</small>
                            </div>
                        </li>
                      
                    </ul>
                    <ul className="us-links">
                            <li><Button disabled> settings</Button></li>
                        </ul>
                    <h3 className="tc"><Link onClick={Signout} to="#" title="">Logout</Link></h3>
                            </div >
                        </div >
            </div >
        </div >
        </div>
    </header >
        )
}

export default NavBar
