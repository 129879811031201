import React from 'react'
import {Link} from 'react-router-dom'
import { Avatar, Button  } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PerformanceIcon from '@material-ui/icons/TrendingUp';
import './accountReview.css';
import * as Common from '../../Utils/Common'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {    
      border:'5px solid #fff'
    },
  }));

  export default function  AccountReviewBlock() {

    const classes = useStyles();
    return (
        <div className="user-data full-width">
            <div className="user-profile">
                <div className="username-dt">
                        <div className="usr-pic">
                            {/* { `${Common.baseURL()}/storage/${Common.getUser().photo}`} */}
                        <Avatar
                            className={classes.large} 
                            sx={{ height: '110px', width: '110px' }}
                                src={ Common.getUser().id != 0 && `${Common.baseURL()}/storage/${Common.getUser().photo}`} 
                                alt="from"
                              >                                
                              </Avatar>
                         </div>
                        
                </div>
                    <div className="user-specs">
                        <h3>{Common.getUser().id != 0 &&  Common.getUser().name}</h3>
                        <p>{Common.getUser().id != 0 &&  Common.getUser().profile.Position}</p>
                         <p>{ Common.getUser().id != 0 &&  Common.getUser().profile.unit.Name}</p>
                    </div>
                    <div className="vProf">
                    <Button disabled> My Profile</Button>
                    </div>
                    <div className="line"><hr/></div>
                    
            </div>
                <ul className="">
                    <li className="kpi" id="i1">
                        <div className='b1'>
                            <PerformanceIcon sx={{width:'20px', height:'20px'}} />
                        </div>
                        <div className='b2'>
                            <h4>My Team Performance</h4>
                        <span>...%</span>
                        </div>
                    </li>
                    {/* <li className="kpi" id="i2">
                        <div className='b1'>
                            <h1>col</h1>
                        </div>
                        <div className='b2'>
                            <h4>Likes</h4>
                        <span>3,36</span>
                        </div>
                    </li>
                    <li className="kpi" id="i3">
                    <div className='b1'>
                            <h1>col</h1>
                        </div>
                        <div className='b2'>
                            <h4>Followers</h4>
                        <span>155</span>
                        </div>
                    </li>
                    <li className='kpi' id="i4">
                    <div className='b1'>
                            <h1>col</h1>
                        </div>
                        <div className='b2'>
                            <h4>New Members</h4>
                        <span>2,000</span>
                        </div>
                    </li> */}

                </ul>
                
                <div className="dashB">
                     <Button disabled> My Dashboard</Button>
                    </div>
        </div>
    )
}

 
