import { TextField } from '@material-ui/core';
import React from 'react'

export default function InputBox(props ) {
    const {name,label,value, error=null, onChange,rows,variant='standard', ...other} = props;
    return (
        <TextField
        label={label}
        variant={variant}
      
        rows={rows}
        name={name}
        value={value}
       multiline 
       onChange={onChange} 
       {...other }
       {...(error && {error:true,helperText:error})}/> 
    )
}
