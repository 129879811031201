import { Grid, Box, List,ListItem, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React ,{useState, useEffect}  from 'react'
import ShareIcon from '@material-ui/icons/Share';
import {Controls} from './controls/Controls'
import {useForm , Form }from './useForm';
import * as  TaskService from './../services/TaskService'
import * as Common from '../../../Utils/Common';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';





const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
       
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
  }));

  const initValues ={
    id:0,
    user:{id:0,name:'none'},
 

}

export default function TaskFowardForm(props) {


    const {values, setValues, errors, setErrors, handleInputChange,resetForm} = useForm(initValues,false,()=>{});

    const [users, setUsers] = useState([]);

    const {save, recordsForEdit, popupShareForm} = props;

    const [sharedList, setSharedList] = useState([]);

    const classes = useStyles();


    const add = (user) =>{

    //verify that the user is not the task owner
       console.log('user selected',user)
        
       if(recordsForEdit.user_id != user && sharedList.findIndex( (e )=> e.id == user) == -1){
        user = users.find( _user => _user.id == user)
        setSharedList([...sharedList,user] )
        
       }
       
    }
    
    const remove = (index) =>{
     
        let newArr = sharedList;
        newArr.splice(index,1)
        setSharedList([...newArr])

        

    }
     

    const share = () =>{

        console.log('task to be shared',recordsForEdit)

        sharedList.forEach((user)=>{
            recordsForEdit.user_id = user.id;
            recordsForEdit.id=0;
            recordsForEdit.status ="New"
            save(recordsForEdit,resetForm)
            setSharedList([] )

        })
        
    }

    useEffect(()=>{
       // console.log("values",values)
        TaskService.getUsersCollections()
        .then(list =>{
            setUsers(list)
        })
          
      },[])

      useEffect(()=>{
        console.log('Shared List', sharedList)
        
    },[sharedList])
   

    return (
        <Form>
            <Grid container>
                <Grid item xs={12} sx={{display:'flex'}}>
                        <Controls.SelectBox
                        label="To"
                        name="user"
                        value={values.user}
                        onChange={handleInputChange} 
                        options={users}
                        error={errors.user_id}
                        />
                        <Controls.ButtonBox 
                               
                                text="Add"
                                xs={6}
                                onClick={()=>{ add(values.user)}}
                            />
                </Grid>
                <List className={classes.root}>        
                    {sharedList.map((user ,index)=>

                        <ListItem alignItems="flex-start" key={index}>
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                        primary={user.name}
                        secondary={
                            <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                              {user.profile !=null && user.profile.Position}
                            </Typography>
                            </React.Fragment>
                        }
                        />
                        <ListItemSecondaryAction onClick={()=>{ remove(index)}}>
                                    <IconButton edge="end" aria-label="delete"
                                    >
                                    <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                        </ListItem>
                       
                    )}
                </List>

                <Grid item xs={12}>
                        <Controls.ButtonBox 
                           
                            text="Share"
                            onClick={()=>{share()}}
                        />
                        <Controls.ButtonBox 
                                color="secondary"
                                text="Close"
                                sx={{margin:"5px"}}
                                onClick={resetForm}
                        />
                          
                </Grid>
            </Grid>          
        </Form>
    )
}
