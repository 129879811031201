import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';


export   function useForm(initValues, validateOnChange=false, validate) {
    const [values,setValues] = useState(initValues);

    const [errors,setErrors] = useState({});


    const handleInputChange = e => {
        const {name,value} = e.target;
        setValues({
            ...values,
            [name]:value
        })
         if(validateOnChange)
            validate({[name]:value})
 
       };
    const resetForm = () =>{
        setValues(initValues);
        setErrors({});  
    }
    return{ 
        values,
        setValues,
        errors,
        setErrors, 
        handleInputChange,
        resetForm 
    }
}

const useStyles = makeStyles((theme)=>({
    root:{
        '& .MuiFormControl-root ': {
            margin: theme.spacing(2),
            width: '95%',
        },
    }
}));

export   function Form(props) {
    const classes = useStyles();
    const {children, ...other} = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}
    