import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Button,
  Badge,
  Container,
  Grid,
  makeStyles,
  Checkbox,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Table,
  fabClasses,
} from "@material-ui/core";
 
 
import { Controls } from "./controls/Controls";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import Popup from './../components/Popup'
 

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TeamIcon from "@material-ui/icons/SupervisedUserCircle";

import Folders from './../components/Folders'
import DocsTable from "./../components/DocsTable";
import DocForm from  "./DocForm"



import AppBar from '@material-ui/core/AppBar';
// import Box from '@material-ui/core/Box';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';

import * as DocService from '../services/DocService'

import Fab from '@material-ui/core/Fab';
import { styled } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "50%",
  },
  newButton: {
    position: "absolute",
    right: "0px",
  },
}));

const createRec = (id, shortName, name, icon, color, parent_id) => {
  return { id, shortName, name, icon, color, parent_id }
}

const departments = [
  createRec(0, 'ICT', 'ICT Department', 'icon', 'red', 0),
  createRec(1, 'QHSE', 'Quality Department', 'icon', 'red', 0),
  createRec(2, 'MECHANICAL', 'Mechanical Department', 'icon', 'red', 0)
]
const units = [
  createRec(0, 'ICT', 'ICT Unit', 'icon', 'red', 0),
  createRec(1, 'HSE', 'HSE Unit', 'icon', 'red', 1),
  createRec(2, 'Quality', 'Quality Unit', 'icon', 'red', 1),
  createRec(3, 'DCC', 'Document Control Unit', 'icon', 'red', 1)
]

const documents = [
  createRec(0, 'Procedures', 'ICT Unit', 'icon', 'red', 0),
  createRec(1, 'Policies', 'HSE Unit', 'icon', 'red', 1),
  createRec(2, 'Forms', 'Quality Unit', 'icon', 'red', 1),
  createRec(3, 'Standards', 'Document Control Unit', 'icon', 'red', 1)
]


const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  height:"10px",
  margin: '0 auto',
});

export default function Index() {
  const classes = useStyles();
  const [data, setData] = React.useState(departments);
  const [currentData, setCurrentData] = React.useState(0);
  const [loadTable, setLoadTable] = React.useState(false)
  const [openPopup, setOpenPopup] = useState(false);

  const [updateTable,setUpdateTable] = useState(0);

  const [recordsForEdit,setRecordsForEdit] = useState(null);


  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  //Load data for the folder components
  //Based on the index of data
  const loadData = (index) => {

 
    switch(index){

      case 0:
        setData(departments);
        setCurrentData(currentData+1);
        setLoadTable(false);
      break;

      case 1:
        setData(units);
        setCurrentData(currentData+1);
        setLoadTable(false);
      break;

      case 2:
        setData(documents);
        setCurrentData(currentData+1);
        setLoadTable(false);
      break;

      default:
        setLoadTable(true);
      break;
    }
   
  }

  const loadDocuments = () => { };



  const openPopupFunction = (row) => {

    setOpenPopup(true);
    setRecordsForEdit(row);
  }
  //   NEW AND UPDATE

  const save =  (doc, resetForm)=>{

   alert(doc.id)
    if(doc.id==0 || doc.id ==undefined){
      resetForm()
      alert('saving')
      DocService.createDoc(doc).
      then((res)=>{

        setRecordsForEdit(res.data.doc)
        setUpdateTable(Math.random())
        console.log('RECORDS NEW',recordsForEdit)
      });
      
      //send notification only to others
      // if(task.created_by != task.user_id){
      //   TaskService.sendNotificaiton(task.created_by,task.user_id)
      // }
      
      
    }else{
      alert('updating')
      
      DocService.updateDoc(doc)
      .then((res)=>{
        setRecordsForEdit(res.data.doc)
        setUpdateTable(Math.random())
      })
      .catch((error)=>{

        console.log(error)

      })
      // setRecordsForEdit(DocService.updateDoc(doc))
      // console.log('RECORDS',recordsForEdit)
    }
    // resetForm()
    // setRecordsForEdit(null)
    // setOpenPopup(false) 
    // //setRecords(TaskService.getAllTasks() )

    // reload()
      
    // setNotify({
    //   isOpen: true,
    //   message: 'Submitted Successfuly',
    //   type:'success'
    // })



  } 

  useEffect(()=>{

    // //Clear the form on popup close
    // if(!openPopup){
    //   setRecordsForEdit({id:0});
    // }

  },[openPopup])

  return (
    <>
     <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
        
            <Breadcrumbs aria-label="breadcrumb" sx={{color:"#fff"}}>
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="/e-library/"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                E-Library
              </Link>
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="#"
                onClick={()=>{setData(departments); setCurrentData(currentData+1); setLoadTable(false);}}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Departments
              </Link>
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="#"
                onClick={()=>{setData(units); setCurrentData(currentData+1); setLoadTable(false);}}
             
              >
                <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Units
              </Link>
               
              
            </Breadcrumbs>
             
          <StyledFab sx={{marginLeft:"20%"}}  color="secondary" aria-label="add" onClick={()=> {setOpenPopup(true); setRecordsForEdit({id:0});}}>
            <AddIcon />
          </StyledFab>
        </Toolbar>
      </AppBar>
    </Box>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 1,
        }}
      >
        <Container maxWidth={true}>
          <Grid container spacing={3} square>
            <Box
              sx={{
                padding: "5px",
                width: "103%",
              }}
            >
              <Box sx={{ pt: 1 }}>
                <Toolbar>
                  <Controls.InputBox
                    label="Search Task"
                    className={classes.searchInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="star">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "2px",
                    }}
                  >
                    <Controls.ButtonBox
                      text="Add New"
                      color="primary"
                      variant="outlined"
                      size="small"
                      startIcon={<AddIcon />}
                      className={classes.newButton}
                    />
                  </Box>
                </Toolbar>

                { !loadTable && <Folders data={data} loadData={loadData} current={currentData}/>}
                { loadTable && <DocsTable 
                      openPopupFunction={openPopupFunction}
                      updateTable={updateTable}
                />}
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>


      <Popup openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add A Document to the E-Library "
      >
           
                    <DocForm
                     recordsForEdit = {recordsForEdit}
                     save={save} 
                      />
      </Popup>
    </>
  );
}
