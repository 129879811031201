import React ,{useState,useEffect} from "react";
import { Box ,Avatar ,Button , Badge, 
Container, Grid, makeStyles, Checkbox,
Paper, TableBody, TableRow , TableCell, 
Toolbar, InputAdornment, Table, fabClasses } from "@material-ui/core";
import { Helmet } from "react-helmet";
import IhubTable from "./IhubTable";
import IhubToolBar from "./IhubToolBar";
import DashboardCard from "./DashboardCard";
import customers from "../../../__mocks__/customers";
import TaskForm from "./TaskForm";
import TaskFowardForm from './TaskForwardForm'
import MiniDashBoard from "./MiniDashBoard";
import useTable from "./useTable"
import * as  TaskService from './../services/TaskService' 
import {Controls }from "./controls/Controls";
import SearchIcon from '@material-ui/icons/Search'; 
import AddIcon from '@material-ui/icons/Add'; 
import Popup from './../components/Popup'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'; 
import CloseIcon from '@material-ui/icons/Close'; 
import Notification from './Notification';
import ConfirmationDialog from './ConfirmDialog'
import StatusUpdate from './StatusUpdate'
import moment from 'moment'
import  { useCountdown } from 'use-moment-countdown'
import * as Common from '../../../Utils/Common';
 

const useStyles = makeStyles((theme)=>({
  pageContent:{
     margin:theme.spacing(5) ,
     padding:theme.spacing(3)
  },
  searchInput:{
    width:'50%'
  },
  newButton:{
    position:'absolute',
    right:'0px'
  }
}))

const headCells = [
  //{ id: 'from', string: false, disablePadding: false, label: 'From' },
  { id: 'ToDo', string: true, disablePadding: true, label: 'Todo' },
  { id: 'Planned', string: true, disablePadding: false, label: 'When?' },
  { id: 'Owner', string: true, disablePadding: false, label: 'Owner' },
  { id: 'Status', string: true, disablePadding: false, label: 'Status' },
  { id: 'Timeline', string: true, disablePadding: false, label: 'Due' },
  { id: 'Priority', string: true, disablePadding: false, label: 'Priority' },
  // { id: 'Type', string: true, disablePadding: false, label: 'Type' },
  { id: 'Actions', label:'Actions',disabelSorting:false}
];

export default function Tasks(props) {

  const {taskType} = props;
  const classes = useStyles();
  const [records,setRecords] = useState([]);
  const [taskUpdates,setTaskUpdates] = useState({today:0,total:0,completed:0,performance:'...',month:''})
  const [recordsForEdit,setRecordsForEdit] = useState(null);
  const [filterFn, setFilterFn] = useState({fn:items=>{ return items; }}); 
  const [openPopup, setOpenPopup] = useState(false)
  const [openSharePopup, setOpenSharePopup] = useState(false)
  const [notify, setNotify] = useState({isOpen:false, message:'',type:''});
  const [confirmDialog, setConfirmDialog] = useState({isOpen:false,title:'',subTitle:''});
  const [statusDialog, setStatusDialog] = useState({isOpen:false,title:'',subTitle:''});

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  const { TableContainerBlock, 
    TableHeader , TablePaging,
     pagingAndFilterRecords } = useTable(records,headCells,filterFn);

  const   handleSearch = e =>{
      let target = e.target;
      setFilterFn({
        fn:items =>{
          if(target.value==""){
              return items; 
          }else{
             return items.filter(x=>x.task.toLowerCase().includes(target.value))
          }
        }
      })
  }

  const save = (task, resetForm)=>{
    if(task.id==0){
      TaskService.createTask(task)
      //send notification only to others
      if(task.created_by != task.user_id){
        TaskService.sendNotificaiton(task.created_by,task.user_id)
      }
      
    }else{
      
      TaskService.updateTask(task)
    }
    resetForm()
    setRecordsForEdit(null)
    setOpenPopup(false) 
    //setRecords(TaskService.getAllTasks() )

    reload()
      
    setNotify({
      isOpen: true,
      message: 'Submitted Successfuly',
      type:'success'
    })



  } 

  const reload = () =>{

      
    if(taskType=='sent'){
      
      TaskService.getMySentTasks(Common.getUser().id)
      .then(list =>{
        setRecords(list)
      })
      console.log("here User Id",Common.getUser().id)

      TaskService.getMySentTasks(Common.getUser().id)
      .then(list =>{
        setRecords(list)
      })
      
      tasksStats()
    }else{
      console.log("my User Id",Common.getUser().id)

      TaskService.getMyTasks(Common.getUser().id)
      .then(list =>{
        setRecords(list)
      })
      TaskService.getMyTasks(Common.getUser().id)
      .then(list =>{
        setRecords(list)
      })
      tasksStats()

    }

  }
  const openInPopup = (item) =>{

      setRecordsForEdit(item)
      setOpenPopup(true)
  }

  const onDelete = id =>{
   // if(window.confirm('Are you sure you want to delete this record?')){
     setConfirmDialog({
       ...confirmDialog,
       isOpen: false,
     })
      TaskService.deleteTask(id)
      if(taskType=='sent'){
        TaskService.getMySentTasks(Common.getUser().id)
        .then(list =>{
          setRecords(list)
        })
  
        TaskService.getMySentTasks(Common.getUser().id)
        .then(list =>{
          setRecords(list)
        })
        
        
      }else{
        TaskService.getMyTasks(Common.getUser().id)
        .then(list =>{
          setRecords(list)
        })
        TaskService.getMyTasks(Common.getUser().id)
        .then(list =>{
          setRecords(list)
        })
  
  
      }
      setNotify({
        isOpen: true,
        message: 'Deleted Successfuly',
        type:'error'
      })
     
      tasksStats()

   // }
    
  }

  const updateTaskStatus = (id,status)=>{
    TaskService.updateTaskStatus(id,status)
    reload()
  }

  const shareTask = (task) =>{
    
    setOpenSharePopup(true); 
    // setRecordsForEdit(null);
  }

  const tasksStats = ()=>{
    let updates = {
      today:0,total:0,completed:1,performance:0,month:'August 2021'
    }
    let d = new Date();
    console.log('Month',d.getMonth('MM')+1)
    TaskService.getTaskStats(d.getMonth()+1,Common.getUser().id)
    .then(response =>{
      console.log('STAT HERE:',response)
      setTaskUpdates({...taskUpdates,
        total: response.total.length,
        completed:response.completed.length,
        month:monthNames[d.getMonth()]+' '+d.getFullYear()
      })
    })      
    

    console.log("updates",taskUpdates)

    
  }

  useEffect(()=>{
    
          // setRecords(TaskService.getMyTasks(1));
          if(taskType=='sent'){
        
            TaskService.getMySentTasks(Common.getUser().id)
          .then(list =>{
            setRecords(list)
          })
            
          }else{
            
            TaskService.getMyTasks(Common.getUser().id)
            .then(list =>{
              setRecords(list)
            })
          }
        

          tasksStats()
        
          console.log('tasks')
          console.log(records)
          
    
  },[])

  const setColor = (status,dueDate) =>{

    if((Date.parse(dueDate) -new Date() ) < 0 && status !="Completed"){
       
        return "#FF0000"
    }else{
      switch(status){
        case "New":
          return "#00b0ff";
        break;
        case "Recieved":
          return "#964B00"
        break;
        case "Progress":
          return "#FFA500"
        break;
        case "Submitted":
          return "#696969";
          break;
        case "Completed":
          return "#44b700"
        break;
        default:
  
        
  
      }
    }
 

  }

  const setPriorityColor = (priority) =>{

    switch(priority){
      case "Normal":
        return "#00b0ff";
      break;
      case "Urgent":
        return "#ff0000"
      break;
      default:

      

    }
  }

  
  return (
    <>
      

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 1,
        }}
      >
        <Container maxWidth={true}>
          <Grid container spacing={3} square>
            <MiniDashBoard taskUpdates={taskUpdates} />
            <Box
              sx={{
                padding: "5px",
                width: "103%",
              }}
            >
              <Box sx={{ pt: 1 }}>
           
              
                {/* <IhubTable customers={customers} /> */}
                  <Toolbar>
                    <Controls.InputBox label="Search Task" 
                      className={classes.searchInput}
                      InputProps={{
                        startAdornment:( 
                        <InputAdornment position="star">
                            <SearchIcon />
                          </InputAdornment> )
                      }}
                      onChange={handleSearch}
                    />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding:'2px',
                          

                        }}
                      >
                          <Controls.ButtonBox 
                          text="Add New"
                          color="primary"
                          variant="outlined" 
                          size="small"
                          startIcon={<AddIcon />}
                          className={classes.newButton}
                          onClick={()=> {setOpenPopup(true); setRecordsForEdit(null);}}/>  
                    </Box>
                    
                  </Toolbar>
                  <TableContainerBlock>
                  <Table
                    // className={classes.table}
                    aria-labelledby="tableTitle"
                    size='small'
                    aria-label="enhanced table"
                  >
                    <TableHeader/>
                    <TableBody>
                    {
                      pagingAndFilterRecords().map( row =>(
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          // selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              // checked={isItemSelected}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="td"  scope="row" padding="none"
                              sx={{
                                backgroundColor:setColor(row.status,row.due_date),
                                color:'white',
                                borderLeft:"3px solid #3d5afe",
                                width:'100%'
                                }}>
                            <Badge color="primary" badgeContent={row.sno}>
                                <Box sx={{fontSize:'12px', padding:'5px', width:'200px'}}
                                onClick={()=>{ openInPopup(row)}}>
                                  <Box >id:{row.id} - {row.type}</Box>
                                  {row.task}
                                  <Box sx={{fontSize:"12px"}}>
                                 { moment(row.scheduled).fromNow()   } By {row.owner && row.owner.name}
                                  </Box>
                                </Box>
                            </Badge>
                          </TableCell>
                          <TableCell component="th" width={300} scope="row" sx={{ width:500}} >
                            <Box
                            
                            sx={{
                              fontSize:'12px',
                            }}>
                              
                            { row.scheduled !=''  && moment(row.scheduled).format('DD/MM/YYYY HH:mm:ss')}
                            
                          </Box>
                          </TableCell>
                          <TableCell sx={{width:500}} >
                            
                              <Avatar
                                src={row.user != null &&  `${Common.baseURL()}/storage/${row.user.photo}`}
                                alt="from"
                              >                                
                              </Avatar>
                           
                              {row.user != null &&  row.user.name}
                          </TableCell>                       
                          <TableCell align="left"   sx={{backgroundColor:setColor(row.status,row.due_date)}}>
                          <Box sx={{fontSize:'10px', width:'90px'}}
                                 >
                              <Button sx={{color:'#ffffff'}}
                                onClick={()=>{
                                   let display = true
                                  if(row.status =="Completed"){
                                    display = false
                                  };  
                                  setStatusDialog({
                                    isOpen:display,
                                       id:row.id,
                                    title:"Update Task Status. ",
                                    subTitle:"If you choose completed you can't undo !",
                                    onConfirm:()=>{}
                                  })
                                  }}
                              >
                                {row.status}
                              </Button>
                              </Box>
                          </TableCell>
                          <TableCell align="right">
                             <Box sx={{fontSize:'12px', width:'110px'}}
                                 >
                         
                            {moment(row.due_date).format('DD/MM/YYYY')}

                            <br/>

                            Time Left:{moment.utc(moment(row.due_date).diff(moment())).format("HH:mm:ss")}
                              </Box>
                          </TableCell>
                          <TableCell sx={{backgroundColor:setPriorityColor(row.priority)}}>
                              <Button sx={{color:'#ffffff'}} >
                                {row.priority}
                              </Button>
                          </TableCell>
                        
                          <TableCell>
                             <Controls.ActionButton color="primary">
                                <EditOutlinedIcon fontSize="small"
                                onClick={()=>{ openInPopup(row)}}/>
                             </Controls.ActionButton>
                             {Common.getUser().id == row.created_by && (
                             <Controls.ActionButton color="scondary "
                              onClick={()=>{
                               
                                setConfirmDialog({
                                  isOpen:true,
                                  title:"Are you sure to delete?",
                                  subTitle:" You cannot undo after delete!",
                               
                                  onConfirm:()=>{
                                    onDelete(row.id)
                                  }
                                })
                                }}>
                                <CloseIcon fontSize="small"/>
                             </Controls.ActionButton>
                             )}
                          </TableCell>
                        
                      </TableRow>
                    ))
                    }
                    </TableBody>
                    </Table>
                  </TableContainerBlock>
                  <TablePaging />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>

      <Popup openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Set a Task "
      >
           <TaskForm 
           recordsForEdit = {recordsForEdit}
           save={save} 
           popupShareForm={shareTask}/>

      </Popup>

      <Popup openPopup={openSharePopup}
        setOpenPopup={setOpenSharePopup}
        title="Share This Task With Colleagues "
      >
           <TaskFowardForm
           recordsForEdit = {recordsForEdit}
           save={save} />

      </Popup>
        <Notification
        notify={notify}
        setNotify={setNotify } />
        <ConfirmationDialog 
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        
        />
      <StatusUpdate
        confirmDialog={statusDialog}
        setConfirmDialog={setStatusDialog}
        updateTaskStatus ={updateTaskStatus}
      
      />
    </>
  );
}
