import React from 'react'
import {KeyboardDatePicker, MuiPickersUtilsProvider,DateTimePicker}  from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';

export default function  (props) {

    const {name, label, value, onChange} = props;

    const convertToDefEventPara =(name,value)=>({
        target:{
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <KeyboardDatePicker disableToolbar 
            inputVariant="outlined"  
            label={label}
            placeholder={label}
            name={name}
            formate="MMM/dd/yyyy"
            value={value}
            onChange={date => onChange(convertToDefEventPara( name,date ))}
            >
                  
            </KeyboardDatePicker> */}
            <DateTimePicker
            label={label}
            placeholder={label}
            formate="MMM/dd/yyyy"
            inputVariant="outlined"
            value={value}
            onChange={date => onChange(convertToDefEventPara( name,date ))}
        />

        </MuiPickersUtilsProvider>
    )
}
