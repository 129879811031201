import InputBox from './InputBox';
import RadioBox from './RadioBox';
import SelectBox from './SelectBox'; 
import DateBox from './DateBox';
import TextBox from './TextBox';
import ButtonBox from './ButtonBox';
import ActionButton from './ActionButton'
import FileInput from './FileInput';

export const Controls ={
    InputBox,
    RadioBox,
    SelectBox,
    DateBox,
    TextBox,
    ButtonBox,
    ActionButton,
    FileInput,
     
}