import { TextField } from '@material-ui/core';
import React from 'react'

export default function TextBox(props ) {
    const {name,label,value,error=null, onChange,type} = props;
    return (
        <TextField
        label={label}
        variant="standard"
        type={type}
        name={name}
        value={value}
        {...(error && {error:true,helperText:error})}
       onChange={onChange} /> 
    )
}
