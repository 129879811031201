import React, { useState ,useEffect} from 'react';
import {
    Box,
} from "@material-ui/core";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import * as FileService from '../services/FileService'
import * as Common from '../Utils/Common'

export default function Pdf() {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file,setFile] = useState({});
  
    function onDocumentLoadSuccess({numPages}){
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offSet){
      setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }
  
    function changePageBack(){
      changePage(-1)
    }
    
    function changePageNext(){
      changePage(+1)
    }

    let { id } = useParams();
    let _file =''
    useEffect(()=>{
      // alert(id)
      FileService.getFile(id)
      .then((response)=>{
        setFile(response.data.file);
        // alert(Common.baseURL() +'/'+ _file.Path)

        // console.log("DocsTable-97",response.data.docs)
      })
    },[])
  
    return (
    

      <div className="App">
      
        <header className="App-header">
          <Document file={Common.backEndURL() +'/'+ file.Path} onLoadSuccess={onDocumentLoadSuccess}>
            <Page height="600" pageNumber={pageNumber} />
          </Document>
          <p> Page {pageNumber} of {numPages}</p>
          { pageNumber > 1 && 
          <button onClick={changePageBack}>Previous Page</button>
          }
          {
            pageNumber < numPages &&
            <button onClick={changePageNext}>Next Page</button>
          }
        </header>
        <center>
          <div>
            <Document file={Common.backEndURL() +'/'+ file.Path} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(
                new Array(numPages),
                (el,index) => (
                  <Page 
                    key={`page_${index+1}`}
                    pageNumber={index+1}
                  />
                )
              )}
            </Document>
          </div>
        </center>
      </div>
    );
}
