import React,{ useEffect, useState } from 'react';

import { Link,Redirect } from 'react-router-dom';
import  NavBar  from '../../components/NavBar';
import LeftSide  from '../../components/leftBlock';
import NoticeBar from '../../components/NoticeBar';
import Header from './components/Header';
import Index from './components/Index'


export default function ELibrary() {
    if(!sessionStorage.getItem('token') ){
        
        return <Redirect to={"/signup"} />;
    }
    return (
        <div className="hub">

        <NavBar/>
        <NoticeBar />
       <main>
           <div className="main-section ">
               <div className="container ">
                   <div className="main-section-data ">
                       <div className="row ">
       
                            <LeftSide />
                             
                            <div className="col-lg-9 col-md-9 no-pd ">


                                 
                                                    <Header />


                                                    <section className="content">
                                               
                                                      
                                                         <Index />
                                               
                                                    </section>
                                        




                            </div>

                        
       
                       </div>
                   </div>
               </div>
           </div>
       </main>
       
       
       {/* <PostPop />
       <ChatBoxIcon />
        */}
       </div>
          
    )
}
