import React from 'react'

function profile() {
    return (
        <div>
            Profile
        </div>
    )
}

export default profile;
