import { Alert, makeStyles, Snackbar } from '@material-ui/core';
import React from 'react'


const useStyles = makeStyles((theme)=>({
    root:{
        top: theme.spacing(90),
         
    }

}))


export default function Notification(props) {

    const {notify, setNotify} = props;
    const classes = useStyles();
    const handleClose = (event, reason) =>{
        if(reason==='clickaway'){
            
        }
        setNotify({
            ...notify,
            isOpen:false
        })
    }
    
    return (
        <Snackbar
        className={classes.root}
        open={notify.isOpen}
        autoHideDuration={3000}
        anchorOrigin={{vertical:'top',horizontal:'right'}}
        onClose={handleClose}>
            <Alert
            severity={notify.type}
            onClose={handleClose}>

                {notify.message}

            </Alert>
        </Snackbar>
    )
}
