import React from 'react'

function rel() {
    return (
        <div>
            Relationship
        </div>
    )
}

export default rel;
