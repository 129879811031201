import axios from 'axios'

export const baseURL = ()=>{
   return "https://ihub.mudiame.com";
    // return "http://localhost:3000";
}
export const backEndURL = ()=>{
    // return "https://ihub.izone5.online/ihub-backend/public/";
    return "https://ihub-backend.mudiame.com"

    //  return "http://localhost:8000";
 }
export const basePath = ()=>{
    // return "https://ihub.mudiame.com";
     return "C:/laragon/www/2022/Apps/ihub/ihub-backend/public";
 }
export const getUser = () =>{
    const userStr = sessionStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    else return {id:0};
}

export const getAllUsers = async () =>{
    const data = await axios.get("auth/users")
    const users = await data.data;
   
    
    return users;
}
export const getToken = () => {
    return sessionStorage.getItem('token') || null;

}

export const setUserSession = (token,user) => {
    console.log('setting session')
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("user",JSON.stringify(user))

}

export const removeUserSession = () =>{
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
}

 