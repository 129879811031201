import axios from 'axios';
import { localeData } from 'moment';

const KEYS ={
    tasks:'tasks',
    taskId:'taskId'
}
 
export const addFiles = (data)=>{
    let files= JSON.parse(localStorage.getItem('files'));
    let myArray = [];
    if(files ==null){
        myArray.push(data);
    }else{
        myArray = files;
        myArray.push(data)
    }
   
    //save item in local storage
    localStorage.setItem('files',JSON.stringify(myArray))
    console.log('File saved !')
}

export const addFile = async (data) =>{
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    
   
    let response = await axios.post("file-upload",data,config);
 
    return  response;
   
 }
  

export const getFiles = async (docId) => {

    let response= await axios.get(`files/${docId}`);
    
    return response;
    
 }

export const deleteFiles = (data,selected) => {

    if(selected != null){

        selected.forEach( (file) =>{
            let index = data.findIndex(o => o.file == file);
            if(index != -1){
                data.splice(index,1);

            }
        })

        localStorage.setItem('files',JSON.stringify(data))

    }
}
 
 
 

export const sendNotification = async (id,sender,document) =>{

    const data = await axios.get(`notification/new-document/${id}/${sender}/${document}`);

    const response = await data.data;

    return response

}