import React from 'react'

export default function Header() {
    return (
        
                                            <div className="content-header">
                                                <div className="container-fluid">
                                                    <div className="row mb-2">
                                                        <div className="col-sm-12">
                                                            <ol className="breadcrumb float-sm-right">
                                                                <li className="breadcrumb-item"><a href="#">iHub</a></li>
                                                                <li className="breadcrumb-item active">E-Library</li>
                                                                <li className="breadcrumb-item active"></li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
  
        
    )
}
