import { Button, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme)=>({
    root:{
        margin: theme.spacing(3)
    },
    label:{
        textTransform:'none'
    }
}))

export default function ButtonBox(props ) {

    const {text, size, color, variant,onClick, ...other} = props;
    const classes = useStyles();
   
    return (
        <Button
         variant={variant || "contained"}
         size={size || "large"}
         color={color || "primary"}
         onClick={onClick} 
         {...other}
         >
             {text }
        </Button>
    )
}
